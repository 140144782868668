import * as React from "react";
import {Button, message, Modal, Space} from "antd";
import {useRequest} from "ahooks";
import jsonRequest from "../../../../../utils/request/jsonRequest";
import ProjectCancelModal from "../../../project/ProjectCancelModal";
import {useState} from "react";

export type ProjectConfirmActionsProps = {
  project: any;
  reload: () => void;
}

const ProjectContactActions = (props: ProjectConfirmActionsProps) => {

  const {
    project,
    reload,
  } = props

  const [isCancel, setIsCancel] = useState(false)

  console.log(project.updatedAt)

  const {run: run, loading: loading} = useRequest((action: 'ok' | 'cancel') => {
    return jsonRequest(`/account/project/contractor/${project.id}`, {
      method: 'PUT',
      data: {
        stage: 'contacting',
        updatedAt: project.updatedAt,
        action,
      }
    })
      .then(rsp => {
        if (!rsp.success) {
          message.error(rsp.message)
        } else {
          message.success('操作成功')
        }
      }).finally(() => {
        reload();
      })
  }, {
    manual: true
  })

  const confirm = () => {
    Modal.confirm({
      title: '确认需求',
      content: '确认需求后表示您已了解并确认任务需求',
      onOk: () => run('ok'),
    })
  }

  const reject = () => {
    Modal.confirm({
      title: '拒接任务?',
      onOk: () => run('cancel')
    })
  }

  return (<>
    <Space size={16}>
      <Button
        type="primary"
        size='large'
        onClick={() => setIsCancel(true)}
        danger
        {...{loading}}
      >拒绝任务</Button>
      <Button
        type="primary"
        size='large'
        onClick={confirm}
        {...{loading}}
      >确认需求</Button>
    </Space>
    <ProjectCancelModal
      title='拒接任务?'
      label='请填写拒接原因说明'
      api={`/account/project/contractor/${project.id}`}
      data={{
        stage: 'contacting',
        action: 'cancel',
        updatedAt: project?.updatedAt,
      }}
      project={project}
      open={isCancel}
      onSuccess={reload}
      onCancel={() => setIsCancel(false)}
    />
  </>)
}

export default ProjectContactActions
